<template>
  <v-form class="container" ref="form" v-model="valid" lazy-validation>
    <v-card v-if="group">
      <v-card-title> </v-card-title>
      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="group.groupName"
                  :label="$t('prescriptions.prescriptionsGroupName')"
                  :rules="rules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="group.note"
                  :label="$t('notes')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <!-- <v-divider /> -->
                <v-card flat>
                  <v-card-actions
                    :style="
                      $vuetify.theme.isDark
                        ? 'background-color:#363636'
                        : 'background-color:whitesmoke'
                    "
                    class="elevation-1"
                  >
                    <v-row class="ma-2" no-gutters>
                      <v-col style="font-size: 20px" class="button mt-2">
                        {{ $t("prescriptions.prescriptionsItems") }}
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              dark
                              class="float-right"
                              @click="addItem"
                            >
                              <v-icon> mdi-plus</v-icon>
                            </v-btn>
                          </template>

                          <span class="">
                            {{ $t("prescriptions.addPrescriptionItem") }}
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                  <v-divider />
                  <v-card-text>
                    <v-list>
                      <v-list-item v-for="(item, i) in groupItems" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row>
                              <v-col cols="12" md="3">
                                <v-autocomplete
                                  :label="$t('prescriptions.itemName')"
                                  v-model="item.prescriptionItemGuid"
                                  hide-selected
                                  :items="items"
                                  item-text="itemName"
                                  item-value="guid"
                                  :rules="rules"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  :label="$t('prescriptions.dose')"
                                  v-model="item.dose"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  :label="$t('prescriptions.usageItem')"
                                  v-model="item.usageItem"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  :label="$t('notes')"
                                  v-model="item.note"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="removeItem(i)"
                                icon
                                color="red lighten-2"
                              >
                                <v-icon> mdi-close </v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ $t("remove") }}
                            </span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                  <v-divider></v-divider>
                  <!-- </v-virtual-scroll> -->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <v-btn
          color="green darken-1"
          class="white--text"
          @click="save"
          :disabled="!valid"
          :min-width="100"
        >
          <v-icon>mdi-content-save-outline</v-icon>
          {{ $t("save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn to="/prescriptionGroup" color="" text>
          {{ $t("backToList") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  props: [],

  data() {
    return {
      guid: this.$route.params.guid,

      valid: true,
      group: {},
      groupItems: [],
      items: [],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    if (this.notEmptyGuid()) {
      axios
        .get("PrescriptionGroup/GetWithTemplates?guid=" + this.guid)
        .then((response) => {
          this.group = response.data.data;
          this.groupItems = response.data.data.prescriptionTemplates;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    axios
      .get("PrescriptionItems")
      .then((response) => {
        this.items = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  watch: {
    // groupItems(val) {
    //   console.log(val);
    // },
  },

  computed: {},
  methods: {
    notEmptyGuid() {
      return this.guid != null && this.guid != undefined && this.guid != "";
    },

    addItem() {
      this.groupItems.push({});
    },

    removeItem(index) {
      this.groupItems.splice(index, 1);
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.group.prescriptionTemplates = this.groupItems;
        if (this.notEmptyGuid()) {
          axios
            .post("PrescriptionGroup/Update", this.group)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        } else {
          axios
            .post("PrescriptionGroup/Add", this.group)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.$router.push({ path: "/PrescriptionGroup" });
                // setTimeout(() => {

                //   //window.location.href = "/PrescriptionGroup";
                // }, 2000);
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
