var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"container",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.group)?_c('v-card',[_c('v-card-title'),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.prescriptionsGroupName'),"rules":_vm.rules},model:{value:(_vm.group.groupName),callback:function ($$v) {_vm.$set(_vm.group, "groupName", $$v)},expression:"group.groupName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('notes')},model:{value:(_vm.group.note),callback:function ($$v) {_vm.$set(_vm.group, "note", $$v)},expression:"group.note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"elevation-1",style:(_vm.$vuetify.theme.isDark
                      ? 'background-color:#363636'
                      : 'background-color:whitesmoke')},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"button mt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t("prescriptions.prescriptionsItems"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus")])],1)]}}],null,false,2322366851)},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("prescriptions.addPrescriptionItem"))+" ")])])],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-list',_vm._l((_vm.groupItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('prescriptions.itemName'),"hide-selected":"","items":_vm.items,"item-text":"itemName","item-value":"guid","rules":_vm.rules},model:{value:(item.prescriptionItemGuid),callback:function ($$v) {_vm.$set(item, "prescriptionItemGuid", $$v)},expression:"item.prescriptionItemGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.dose')},model:{value:(item.dose),callback:function ($$v) {_vm.$set(item, "dose", $$v)},expression:"item.dose"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.usageItem')},model:{value:(item.usageItem),callback:function ($$v) {_vm.$set(item, "usageItem", $$v)},expression:"item.usageItem"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('notes')},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})],1)],1)],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.removeItem(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])],1)],1)}),1)],1),_c('v-divider')],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/prescriptionGroup","color":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t("backToList"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }